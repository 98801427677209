import classes from "./Bio.module.css";

const Bio = () => {
    return (
        <div className={classes.bio} id="bio">
            <p>
                I have a BSc in Biomedical Engineering from Tel Aviv University. I recently completed my service in the IDF Medical Corps where I
                worked as full stack developer / medical procurement engineer. During my service, I developed two web applications for the IDF that
                are in use, and spent my free time working on my own application for fellow language learning enthusiasts.
            </p>
            <a href="mailto:lior7koren@gmail.com">
                Contact me about <b>junior full stack developer roles</b> in the greater Tel Aviv area.
            </a>
        </div>
    );
};

export default Bio;
