import * as React from "react";
const SvgGithubIcon = (props) => (
  <svg
    width={50}
    height={50}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M25 0C11.187 0 0 11.478 0 25.634 0 36.962 7.162 46.57 17.094 49.956c1.25.241 1.708-.552 1.708-1.233 0-.608-.02-2.221-.031-4.358-6.954 1.545-8.421-3.44-8.421-3.44-1.137-2.958-2.781-3.75-2.781-3.75-2.265-1.589.175-1.556.175-1.556 2.51.178 3.829 2.641 3.829 2.641 2.23 3.92 5.852 2.787 7.281 2.133.225-1.659.869-2.787 1.584-3.428-5.553-.642-11.388-2.846-11.388-12.668 0-2.797.969-5.084 2.573-6.878-.281-.648-1.125-3.254.219-6.784 0 0 2.093-.687 6.875 2.628 2-.57 4.125-.852 6.25-.865 2.125.013 4.25.295 6.25.865 4.75-3.315 6.843-2.628 6.843-2.628 1.344 3.53.5 6.136.25 6.784 1.594 1.794 2.563 4.08 2.563 6.878 0 9.848-5.844 12.015-11.406 12.646.875.77 1.687 2.341 1.687 4.743 0 3.43-.031 6.187-.031 7.02 0 .671.437 1.474 1.719 1.217C42.844 46.558 50 36.945 50 25.634 50 11.478 38.806 0 25 0Z"
      fill="#353338"
    />
  </svg>
);
export default SvgGithubIcon;
